<template>
<div class="">

    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Dashboard</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Leads</a>
                            </li>
                            <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                            </li>
                            <li class="breadcrumb-item active">FAQ
                            </li> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    
    <div  class="content-body">

        <div class="card">
            <div class="card-body table-responsive">
                <table class="table">
                    <tr>
                        <th>
                            #
                        </th>
                        <th>
                           Fullname 
                        </th>
                        <th>
                            Email / Phone
                        </th>
                        
                        <th>
                            Package
                        </th>
                    </tr>

                    <tr v-for="lead,key in leads" :key="lead.id">
                        <td>
                            {{key+1}}
                        </td>
                        <td>
                            <img style="object-fit: cover; width: 30px; height: 30px; border-radius: 50%;" class="shadow" :src="lead.referree.avatar "  alt="">

                            {{ lead.referree.name }}
                        </td>
                        <td>

                            {{ lead.referree.email }} <br>
                            {{ lead.referree.profile?.phone }}


                            
                        </td>
                       
                        <td>
                            
                            <img 
                            v-if="lead.referree.package?.featured_logo"
                            style="object-fit: cover; width: 30px; height: 30px; border-radius: 50%;" 
                            class="shadow" 
                            :src="lead.referree.package?.featured_logo"  
                            alt=""> 
                            <div v-else  class="cc">
                                <h6 class="">no package</h6>
                            </div>
                            <br>

                            {{ lead.referree.package?.title }}

                            
                        </td>
                    </tr>
                </table>
            </div>
        </div>

      

 
        
    </div>

</div>


</template>


<script>
import axios from 'axios'
export default {

    data() {
        return {
            leads: [],
            amount: 0,
            loading: false,
            isAdmin: false,
            walletBalance: ''
        }
    },
    methods: {
        format(value){
            var numeral = require('numeral');

            return numeral(value).format('N 0,0.00')
        },

        getLeads(){
            // alert('ye')

            axios({
                url: process.env.VUE_APP_URL +'/api/leads',
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                },


            })
            .then((response)=>{

                this.leads = response.data

     
                if (localStorage.getItem('user_role')=='admin') {
                    this.isAdmin = true
                }
              
                console.log(response)
            })
            .catch((response)=>{
                console.log(response)
            })

        },




    },
    mounted() {
        this.getLeads()
    },
}
</script>