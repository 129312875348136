<template>
<div class="">

    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Dashboard</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Withdrawal Requests</a>
                            </li>
                            <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                            </li>
                            <li class="breadcrumb-item active">FAQ
                            </li> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    
    <div  class="content-body">

        <div v-show="!isAdmin" class="card col-md-4">
            <div class="card-body">
                <span class="alert alert-info">{{ msg }}</span>
                <div class="form-group">
                    <label for="">Enter Amount (max amount: ${{ format((walletBalance * 0.925)/500) }}) VAT: 7.5%</label>
                    <input type="number" v-model="amount" class="form-control" >
                </div>

                <div class="form-group">
                    <button @click="submitRequest()" class="btn btn-primary float-right">Submit</button>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body table-responsive">
                <table class="table">
                    <thead>
                        <th></th>
                        <th>Amount</th>
                        <th>Bank</th>
                        <th>Account Name</th>
                        <th>Account Number</th>
                        <th>Status</th>
                        <th v-show="isAdmin" ></th>

                    </thead>

                    <tbody>
                        <tr v-for="withdrawal in withdrawalRequests" :key="withdrawal.id">
                            <td></td>
                            <td>$ {{ format(withdrawal.amount/500) }}</td>
                            <td>{{ withdrawal.bank_name }}</td>
                            <td>{{ withdrawal.account_name }}</td>
                            <td>{{ withdrawal.account_no }}</td>
                            <td><span :class="withdrawal.status=='approved'?'badge badge-success':'badge badge-secondary'">{{ withdrawal.status }}</span></td>
                            <td v-show="isAdmin" >
                                <router-link v-show="withdrawal.status=='pending'" :to="'withdrawal/'+withdrawal.id" class="btn btn-sm btn-primary">view</router-link>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


 
        
    </div>

</div>


</template>


<script>
import axios from 'axios'
export default {

    data() {
        return {
            withdrawalRequests: [],
            amount: 0,
            loading: false,
            isAdmin: false,
            walletBalance: '',
            msg: '',
        }
    },
    methods: {
        format(value){
            var numeral = require('numeral');

            return numeral(value).format('N 0,0.00')
        },

        getWithdrawalRequests(){

            axios({
                url: process.env.VUE_APP_URL +'/api/withdrawalRequests',
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                },


            })
            .then((response)=>{

                this.withdrawalRequests = response.data.withdrawalRequest

                this.walletBalance = response.data.wallet_balance


                if (localStorage.getItem('user_role')=='admin') {
                    return this.isAdmin = true
                }
              
                console.log(response)
            })
            .catch((response)=>{
                console.log(response)
            })

        },

        submitRequest(){
            this.loading = true
            axios({
                url: process.env.VUE_APP_URL +'/api/withdrawalRequests',
                method: 'post',
                data:{
                    amount: this.amount * 500
                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('user_token')
                },
            }).then((response)=>{

                this.loading = false

                console.log(response)

                this.msg = response.data.message


                this.getWithdrawalRequests()


            }).catch((err)=>{

                this.loading = false

                console.log(err)

            });
        }


    },
    mounted() {
        this.getWithdrawalRequests()
    },
}
</script>