<template>
<div class="">

    <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
                <div class="col-12">
                    <h2 class="content-header-title float-left mb-0">Dashboard</h2>
                    <div class="breadcrumb-wrapper">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="index.html">Request</a>
                            </li>
                            <!-- <li class="breadcrumb-item"><a href="#">Pages</a>
                            </li>
                            <li class="breadcrumb-item active">FAQ
                            </li> -->
                        </ol>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
    
    <div  class="content-body">

        <div class="col-md-6 mt-5">
            <div class="card">
                <div class="card-body">
                    <div class="form-group">
                        <label for="otp">Enter Otp</label>
                        <input type="number" v-model="otp" class="form-control text-center">
                    </div>
                    <div class="form-group ">
                        <button @click="submitApproval('approved')" class="btn-block btn btn-primary">Approve Payments</button>
                    </div>
                    <div class="form-group ">
                        <button @click="submitApproval('disapproved')" class="btn-block btn btn-danger">Disapprove Payments</button>
                    </div>
                </div>
            </div>
        </div>
 
        
    </div>

</div>


</template>


<script>
import axios from 'axios'
export default {

    data() {
        return {
            withdrawalRequest: [],
            otp: '',
        
        }
    },
    methods: {
        getWithdrawalRequest(){

            axios({
                url: process.env.VUE_APP_URL +'/api/withdrawalRequests/'+this.$route.params.id,
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                },


            })
            .then((response)=>{
                this.withdrawalRequest = response.data
                console.log(response)
            })
            .catch((response)=>{
                console.log(response)
            })

        },
        submitApproval(status){

            alert(status)

            axios({
                url: process.env.VUE_APP_URL +'/api/withdrawalRequests',
                method: 'post',
                data:{
                    otp: this.otp,
                    status: status,
                    type: 'adminApproval',
                    withdrawalId: this.withdrawalRequest.id
                },
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' +localStorage.getItem('user_token')
                },
            }).then((response)=>{
                
                console.log(response)

            }).catch((response)=>{
                console.log(response)

            })

        }
    },
    mounted() {
        this.getWithdrawalRequest()
    },
}
</script>